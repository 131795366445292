import styled from 'styled-components';

import { Tablet } from '@/styles/layout';

const ServiceInstructionSection = () => {
  return (
    <Section>
      <Row data-aos={'fade-up'} data-aos-duration={500} data-aos-once={'true'}>
        <ImageWrapper>
          <Video autoPlay playsInline loop muted>
            <source
              src={
                'https://file.doctornow.co.kr/landing/doctor-select-step.mp4'
              }
            />
          </Video>
          <Gradation />
        </ImageWrapper>
        <ContentBox>
          <Step>진료 예약</Step>
          <Title>{'내가 직접 선택하는\n의사 선생님'}</Title>
          <Description>
            {'평균 진료비와 진료 후기를 보고\n원하는 의사 선생님을 골라보세요.'}
          </Description>
        </ContentBox>
      </Row>
      <RowReverse
        data-aos={'fade-up'}
        data-aos-duration={500}
        data-aos-once={'true'}
      >
        <ImageWrapper>
          <Video autoPlay playsInline loop muted>
            <source
              src={
                'https://file.doctornow.co.kr/landing/diagnosis-detail-step.mp4'
              }
            />
          </Video>
          <Gradation />
        </ImageWrapper>
        <ContentBox>
          <Step>전화로 비대면 진료</Step>
          <Title>{'언제 어디서나\n편하게 진료'}</Title>
          <Description>
            {'365일 24시간 언제든\n전화로 진료받을 수 있어요.'}
          </Description>
        </ContentBox>
      </RowReverse>
      <Row data-aos={'fade-up'} data-aos-duration={500} data-aos-once={'true'}>
        <ImageWrapper>
          <Video autoPlay playsInline loop muted>
            <source
              src={
                'https://file.doctornow.co.kr/landing/send-prescription-step.mp4'
              }
            />
          </Video>
          <Gradation />
        </ImageWrapper>
        <ContentBox>
          <Step>약 픽업</Step>
          <Title>{'처방전은 앱으로 전송하고\n바로 약국에서 픽업'}</Title>
          <Description>
            {'간편하게 조제 신청하고\n대기 없이 약을 바로 찾아올 수 있어요.'}
          </Description>
        </ContentBox>
      </Row>
    </Section>
  );
};
export default ServiceInstructionSection;

const Section = styled.div`
  display: flex;
  padding: 160px 0;
  flex-direction: column;
  align-items: center;
  gap: 142px;
  align-self: stretch;
  background: white;

  @media screen and (max-width: ${Tablet}) {
    gap: 0;
    padding: 0 0 32px 0;
  }
`;

const Row = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${Tablet}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 28px;
    padding: 72px 0 40px 0;
  }
`;

const RowReverse = styled(Row)`
  flex-direction: row-reverse;

  @media screen and (max-width: ${Tablet}) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 480px;
  overflow: hidden;

  @media screen and (max-width: ${Tablet}) {
    width: 280px;
  }
`;

const Video = styled.video`
  -webkit-mask-image: -webkit-linear-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
`;

const Gradation = styled.div`
  position: absolute;
  width: 500px;
  height: 10px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 2.08%,
    #fff 69.74%
  );

  left: -25px;
  bottom: 0;
`;

const ContentBox = styled.div`
  width: 420px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media screen and (max-width: ${Tablet}) {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

const Step = styled.div`
  align-self: flex-start;
  display: flex;
  padding: 5px 12px;
  flex-direction: column;
  align-items: flex-start;

  ${({ theme }) => `
        border-radius: 20px;
        background: ${theme.color.PRIMARY100};
        color: ${theme.color.PRIMARY600};
        ${theme.typography.UI20SB};
        
        @media screen and (max-width: ${Tablet}) {
          ${theme.typography.UI17SB};
        };
    `};
  @media screen and (max-width: ${Tablet}) {
    align-self: center;
  }
`;

const Title = styled.h3`
  white-space: pre-wrap;
  ${({ theme }) => `
        ${theme.typography.UI40B};
        color: ${theme.color.GRAY900};
        
        @media screen and (max-width: ${Tablet}) {
          ${theme.typography.UI28B};
        }
    `}
`;

const Description = styled.div`
  white-space: pre-wrap;
  margin-top: 3px;
  ${({ theme }) => `
        ${theme.typography.UI20R};
        color: ${theme.color.GRAY500};
        
        @media screen and (max-width: ${Tablet}) {
          ${theme.typography.UI17R};
        }
    `}
`;
