import { QRCodeCanvas } from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { EllipsisTextStyle } from '@/components/atom/text/DefaultText';
import { zIndex } from '@/constants/zIndex';
import { Tablet } from '@/styles/layout';

const DownloadFooter = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!window) {
      return;
    }

    const section = 982 + 503;
    window.addEventListener('scroll', () => {
      setIsVisible(window.scrollY + window.innerHeight > section);
    });
  }, []);

  if (!isVisible) {
    return <></>;
  }
  return (
    <Wrapper>
      <Content>
        <Text>QR을 찍으면 병원이 나에게 와요.</Text>
        <QRCodeCanvas value={'https://abr.ge/r968wo'} size={64} />
      </Content>
    </Wrapper>
  );
};

export default DownloadFooter;

const BottomUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  animation: ${BottomUp} 1s ease-in-out forwards;
  z-index: ${zIndex.BottomSticky};

  @media screen and (max-width: ${Tablet}) {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 52px;
  padding: 12px 16px;
  border-radius: 40px 40px 0 0;
  background-color: ${({ theme }) => theme.color.GRAY900};
  width: 100%;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
`;

const Text = styled.p`
  ${({ theme }) => theme.typography.UI24B};
  color: ${({ theme }) => theme.color.WHITE};
  ${EllipsisTextStyle};
  -webkit-line-clamp: 1;
`;
